import Vue from 'vue'
import App from './App.vue'
import router from './router'

// import Meta from 'vue-meta'

Vue.config.productionTip = false
// Vue.use(Meta)



Vue.config.productionTip = false

import { flexView } from '@/utils'
//导入组件库
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

flexView()

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
